:root {
  /* danger */
  --color-danger-50: #ffeaec;
  --color-danger-100: #ffcacd;
  --color-danger-200: #f8958f;
  --color-danger-300: #f06b64;
  --color-danger-400: #f9463a;
  --color-danger-500: #fd3114;
  --color-danger-600: #ef2317;
  --color-danger-700: #dd1212;
  --color-danger-800: #d00008;
  --color-danger-900: #c20000;
  --color-danger-50-contrast: #19121c;
  --color-danger-100-contrast: #19121c;
  --color-danger-200-contrast: #19121c;
  --color-danger-300-contrast: #19121c;
  --color-danger-400-contrast: #19121c;
  --color-danger-500-contrast: #19121c;
  --color-danger-600-contrast: #19121c;
  --color-danger-700-contrast: #fff;
  --color-danger-800-contrast: #fff;
  --color-danger-900-contrast: #fff;

  /* sizes */
  --size-container-width: 1240px;
  --size-container-small-width: 980px;
  --size-banner-subscription-height: 160px;

  /* depths */
  --depth-loading-screen: 100;
}
