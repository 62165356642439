body {
  font-family: 'Inter', sans-serif;
}
.external .content {
  background-image: linear-gradient(var(--color-primary-700), var(--color-primary-900));
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.internal .content {
  min-height: calc(100vh - var(--size-header-height) - var(--size-header-height));
}
